import React from "react"
import PropTypes from "prop-types"
import Header from "../Header"
import Footer from "../Footer"
import "./layout.css"

const Layout = ({ children }) => {

  return (
    <>
    <div className="layout">
      <link href="https://fonts.googleapis.com/css?family=Montserrat:400,600,700|Open+Sans&display=swap" rel="stylesheet"/>
      <Header/>
        <main>{children}</main>
      <Footer/>
    </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
