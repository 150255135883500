import React from 'react'
import './styles.css'


const BlockSimple = (props) => (
  <div className="block-simple__bg">
    <div className="block-simple__wrapper">
      <div className="block-simple__title">{props.title}</div>
    </div>
  </div>
)

export default BlockSimple
