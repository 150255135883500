import React from 'react'
import { Link } from "gatsby"
import './styles.css'

const Header = () => (
  <div>
    <div class="header__wrappper">
      <div class="header">
        <Link aria-current="page" activeClassName="header__main-link" class="header__main-link" to="/">Costa Rica<br></br> Best Choice</Link>
        <div class="header__btm">
          <div class="header__links">
            <Link activeClassName="header__link--active" class="header__link" to="/shuttle-services">Shuttle Services</Link>
            <Link activeClassName="header__link--active" class="header__link" to="/destinations">Destinations</Link>
            <Link activeClassName="header__link--active" class="header__link" to="/tours">Tours</Link>
          </div>
        </div>
        <Link aria-current="page" class="header__button" activeClassName="header__button" to="/book-your-trip">Book your trip</Link>
      </div>
      <nav className="header__toggle">
        <div id="menuToggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul className="header__toggle-menu">
            <Link className="header__toggle-menu-link" to="/shuttle-services">Shuttle Services</Link>
            <Link className="header__toggle-menu-link" to="/destinations">Destinations</Link>
            <Link className="header__toggle-menu-link" to="/tours">Tours</Link>
            <Link aria-current="page" class="header__toggle-menu-link" to="/book-your-trip">Book your trip</Link>
          </ul>
        </div>
      </nav>
    </div>
  </div>
)

export default Header
