import React from 'react'
import './styles.css'

const Masthead = () => (
  <div className="masthead__wrapper">
    <div className="masthead">
      <div className="masthead__text">
        <div>The Best Choice to Discover Costa Rica</div>
      </div>
    </div>
  </div>

)

export default Masthead
