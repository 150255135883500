import React from 'react'
import { Link } from "gatsby"
import './styles.css'

const Footer = () => (
  <div className="footer__wrapper">
    <div className="footer">
      <div className="footer__bottom">
        <div className="footer__contact">
          <div className="footer__contact-text">Questions or special requests?</div>
          <Link className="footer__button" to="/book-your-trip">Contact Us</Link>
        </div>
        <div className="footer__social">
          <a href="/"><img src="/ta.svg" className="footer__icon footer__t-a" alt="icon"/></a>
          <a href="https://www.facebook.com/costaricabestchoice/"><img src="/fb.svg" className="footer__icon footer__fb" alt="icon"/></a>
          <a href="/"><img src="/ig.svg" className="footer__icon footer__ig" alt="icon"/></a>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
